import firebase from '../index';

const payments = firebase.firestore().collection('payments');
const purchaseOrders = firebase.firestore().collection('purchaseOrders');
const promotions = firebase.firestore().collection('promotions');
const promoHist = firebase.firestore().collection('promoHist');
const fbTime = () => firebase.firestore.Timestamp.now();

const createPaymentData = async data => new Promise((resolve, reject) => {
  payments.doc().set(data)
    .then(() => {
      resolve(true);
    })
    .catch((error) => {
      reject(error);
    });
});

const createPurchaseOrder = data => new Promise((resolve, reject) => {
  // TODO: check promo and set used history
  const key = `OD${data.id}`;
  const order = {
    added: fbTime(),
    status: 'pending',
    ...data,
  };
  purchaseOrders.doc(key).set(order)
    .then(() => {
      resolve('Order created.');
    })
    .catch((error) => {
      reject(error);
    });
});

const findPromotion = code => new Promise((resolve, reject) => {
  promotions.doc(code).get()
    .then((doc) => {
      resolve(doc.data());
    })
    .catch((error) => {
      reject(error);
    });
});

const setPromoHist = data => new Promise((resolve, reject) => {
  promoHist.doc(data.code).set(data, { merge: true })
    .then(() => {
      resolve('Promotion created.');
    })
    .catch((error) => {
      reject(error);
    });
});

const updatePromoHist = data => new Promise((resolve, reject) => {
  promoHist.doc(data.code).update(data)
    .then(() => {
      resolve('Promotion Updated.');
    })
    .catch((error) => {
      reject(error);
    });
});

const getPromoHist = code => new Promise((resolve, reject) => {
  promoHist.doc(code).get()
    .then((querySnapshot) => {
      resolve(querySnapshot.data());
    })
    .catch((error) => {
      reject(error);
    });
});

const getLastOrder = () => new Promise((resolve, reject) => {
  purchaseOrders
    .orderBy('id', 'desc')
    .limit(1)
    .get()
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        resolve(doc.data());
        return doc.data();
      });
    })
    .catch((error) => {
      reject(error);
    });
});

const getSingleOrder = ({ odId }) => new Promise((resolve, reject) => {
  purchaseOrders
    .doc(odId)
    .get()
    .then(async (snapshot) => {
      resolve(snapshot.data());
    })
    .catch((e) => {
      reject(e);
    });
});

const getUserOrders = ({ uid }) => new Promise((resolve, reject) => {
  purchaseOrders
    .where('uid', '==', uid)
    .get()
    .then(async (snapshot) => {
      const docs = await snapshot.docs.map(doc => doc.data());
      resolve(docs);
    })
    .catch((e) => {
      reject(e);
    });
});

const getSingleTransaction = ({ invoiceId }) => new Promise((resolve, reject) => {
  payments
    .where('invoiceId', '==', invoiceId)
    .get()
    .then(async (snapshot) => {
      const docs = await snapshot.docs.map(doc => doc.data());
      resolve(docs[0]);
    })
    .catch((e) => {
      reject(e);
    });
});

const getUserTransactions = ({ uid }) => new Promise((resolve, reject) => {
  payments
    .where('uid', '==', uid)
    .get()
    .then(async (snapshot) => {
      const docs = await snapshot.docs.map(doc => doc.data());
      resolve(docs);
    })
    .catch((e) => {
      reject(e);
    });
});

export default {
  fbTime,
  createPaymentData,
  createPurchaseOrder,
  getLastOrder,
  getSingleOrder,
  getSingleTransaction,
  getUserOrders,
  getUserTransactions,
  findPromotion,
  setPromoHist,
  getPromoHist,
  updatePromoHist,
};
